<template>
    <component :is="layout">
        <Snackbar :attr="snackbarAttr"/>
        <template v-if="brandNotFoundError && !isVisibleWhenBrandNotFound">
            <v-row style="height: 100%" align="center" justify="center">
                <v-col cols="10" sm="8" md="6" lg="4">
                    <v-row no-gutters justify="center" style="height: 100%" align="center">
                        <v-col style="text-align: center" cols="12">
                            <v-card elevation="2" class="my-6"> <!-- v-if="isCustomDomain" , see note below -->
                                <v-app-bar color="red darken-2" dark flat dense>
                                    <v-app-bar-title>Not found</v-app-bar-title>
                                </v-app-bar>
                                <v-card-text class="pt-8">
                                    <p>Use the back button to return to the previous site.</p>
                                    <!-- <p>Return to the last page and contact the emergency home for details.
                                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p> -->
                                    <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p>
                                </v-card-text>
                            </v-card>
                            <!-- TODO: when all customer sites are on custom domains we can remove this feature, because we will get the brandprofile by looking up the custom hostname, and if the brandprofile is not found we should at least know the organization and can show it with the unicorn springs style, and if even that is not found then we'll just show the error message above, because searching won't help and we won't allow a customer to search and use some OTHER store on the wrong domain -->
                            <!-- <v-form @submit.prevent="search" @keyup.enter.native.prevent="search" v-if="!isCustomDomain">
                                <p>
                                    Search for a storefront:
                                </p>
                                <v-text-field v-model="searchQuery" ref="searchInput" dense solo :color="primaryColor">
                                    <template #prepend-inner>
                                        <font-awesome-icon :icon="['fas', 'search']" fixed-width/>
                                    </template>
                                </v-text-field>
                                <v-btn @click="search" elevation="4" :style="primaryButtonStyle">Search</v-btn>
                                <p class="mt-8" v-if="!isCustomDomain"><a :href="mainWebsiteURL">Create your own storefront</a></p>
                            </v-form> -->
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>
        <template v-if="!brandNotFoundError || isVisibleWhenBrandNotFound">
            <template v-if="isPublic || (isAuthenticatedReady && isAuthenticated)">
                <router-view></router-view>
            </template>
            <template v-if="!isPublic && !isAuthenticatedReady && !brandNotFoundError">
                <v-row style="height: 100%" align="center" justify="center">
                    <div class="app-splash-loader"></div>
                </v-row>
            </template>
            <template v-if="!isPublic && isAuthenticatedReady && !isAuthenticated">
                <v-row style="height: 100%" align="center" justify="center">
                    <v-col cols="10" sm="8" md="6" lg="4">
                        <LoginCard :nextRoute="this.$router.currentRoute.fullPath"/>
                    </v-col>
                </v-row>
            </template>
        </template>
    </component>
</template>

<style lang="css">
html {
  overflow-y: auto; /* reference: https://vuetifyjs.com/en/getting-started/frequently-asked-questions/ "The scrollbar is showing even though my content is not overflowing vertically" */
}
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import '@/assets/font/NunitoSans/NunitoSans.css'
import Snackbar from '@/components/Snackbar.vue';
import LoginCard from '@/components/LoginCard.vue';

export default {
    name: 'App',

    components: {
        Snackbar,
        LoginCard,
    },

    data: () => ({
        snackbarAttr: {},
        searchQuery: null,
        searchActionTimestamp: null,
    }),

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            session: (state) => state.session,
            brandprofile: (state) => state.brandprofile,
            brandselector: (state) => state.brandselector,
            brandNotFoundError: (state) => state.brandNotFoundError,
            brand: (state) => state.brand,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            isCustomDomain: 'isCustomDomain',
            mainWebsiteURL: 'mainWebsiteURL',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isPublic() {
            return this.$route.meta.isPublic ?? false;
        },
        isVisibleWhenBrandNotFound() {
            return this.$route.meta.isVisibleWhenBrandNotFound ?? false;
        },
        layout() {
            return this.$route.meta.layout ?? 'main-layout';
        },
        hostname() {
            return process.env.VUE_APP_HOSTNAME ?? 'customer.unicornsprings.com';
        },
    },

    watch: {
        $route(newValue, oldValue) {
            console.log(`app.vue route watcher; new ${JSON.stringify(newValue.params.brandprofile)} old ${JSON.stringify(oldValue.params.brandprofile)}`);
            if (newValue.params.brandprofile && newValue.params.brandprofile !== oldValue.params.brandprofile) {
                this.refresh();
            }
        },
        focus() {
            this.refresh();
        },
        // Disabled because redirecting to a search page is confusing for people. Better to just show a message that the requested storefront was not found.
        // brandNotFoundError(newValue) {
        //     // if the requested brand is not found, redirect to the search page
        //     if (newValue && this.$route.name !== 'search') {
        //         this.$router.push({ name: 'search' });
        //     }
        // },
    },

    methods: {
        async refresh() {
            // console.log(`APP.VUE REFRESH, current brandprofile is ${this.brandprofile} and brandprofile param is ${JSON.stringify(this.$route.params.brandprofile)}`);
            // reload brand information in case the selected brandprofile and the previously-loaded brand become out of sync
            // NOTE: not using 'await' because we need these requests to run in parallel for faster display
            if (this.$route.params.brandprofile && (!this.brandprofile || this.brandprofile !== this.$route.params.brandprofile || !this.brand || this.brand.alias !== this.$route.params.brandprofile)) {
                /* await */ this.loadBrandProfileFromPathParam();
            } else if (!this.$route.params.brandprofile) {
                /* await */ this.loadBrandProfileFromHostname();
            }
            /* await */ this.$store.dispatch('refresh');
        },
        async loadBrandProfileFromPathParam() {
            // console.log('app.vue loadBrandProfileFromPathParam');
            this.$store.commit('brandprofile', this.$route.params.brandprofile);
            this.$store.commit('brandselector', 'brandprofile');
            /* await */ this.$store.dispatch('loadBrand');
            /* await */ this.$store.dispatch('loadPalette', { mode: 'light' });
            /* await */ this.$store.dispatch('loadOrganization');
        },
        async loadBrandProfileFromHostname() {
            // console.log('app.vue loadBrandProfileFromHostname');
            // this.$store.commit('brandprofile', null); // don't do this because it causes flicker in the UI; when the new brand information is loaded, the brandprofile will be set and components can update
            this.$store.commit('hostname', window.location.hostname);
            this.$store.commit('brandselector', 'hostname');
            /* await */ this.$store.dispatch('loadBrand');
            /* await */ this.$store.dispatch('loadPalette', { mode: 'light' });
            /* await */ this.$store.dispatch('loadOrganization');
        },
        search() {
            if (Number.isInteger(this.searchActionTimestamp) && this.searchActionTimestamp + 500 > Date.now()) {
                return;
            }
            this.$nav.push({ name: 'search', params: this.$route.params, query: { q: this.searchQuery } });
        },
    },

    created() {
        // console.log(`app.vue: created, initializing with route name ${this.$route.name} and brandprofile param: ${JSON.stringify(this.$route.params.brandprofile)}`);
        // if (this.$route.params.brandprofile) {
        //     this.$store.commit('brandprofile', this.$route.params.brandprofile);
        //     this.$store.commit('brandselector', 'brandprofile');
        // } else {
        //     this.$store.commit('brandselector', 'hostname');
        // }
        // if (this.$route.params.brandprofile) {
        //     this.$store.commit('brandprofile', this.$route.params.brandprofile);
        //     this.$store.commit('brandselector', 'brandprofile');
        // } else {
        //     this.$store.commit('brandprofile', null);
        //     this.$store.commit('brandselector', 'hostname');
        // }
        // this.$store.dispatch('loadBrand');
        // this.$store.dispatch('loadPalette', { mode: 'light' });
        // this.$store.dispatch('loadSession');

        // call when tab is selected
        window.onfocus = () => {
            this.$store.commit('focus', Date.now()); // any components that want to refresh on focus can watch for changes to this value
        };

        // listen for snackbar events coming from any component
        this.$bus.$on('snackbar', (attr) => { this.snackbarAttr = attr; });
    },
    mounted() {
        // console.log(`app.vue: mounted with route name ${this.$route.name} and brandprofile param ${this.$route.params.brandprofile}`);
        if (this.$route.name) {
            this.refresh();
        }
    },
};
</script>
